/* eslint-disable import/prefer-default-export */
import { z } from 'zod';

// PatientAppointmentModal.vue
export const createPatientQueueAndIdSchema = (accountType) => {
  const baseSchema = {
    queue: z.string().min(1, 'Фамилия обязательна'),
  };

  if (accountType === 0) {
    baseSchema.patientId = z
      .number({
        required_error: 'Обязательное поле',
        invalid_type_error: 'Выберите пациента в списке',
      })
      .nonnegative();
  }

  return z.object(baseSchema);
};
